// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g0lAt{position:relative;max-width:100%;min-height:178px;background-color:#f4f4f4;border-radius:8px}.g0lAt._6kXJk{background-color:#181716}.uha\\+q{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardSlider": "g0lAt",
	"dark": "_6kXJk",
	"loader": "uha+q"
};
module.exports = ___CSS_LOADER_EXPORT___;
