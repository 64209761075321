import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { DEFAULT_OPTIONS } from '~/components/Slider';
export default defineComponent({
    name: 'CardSlider',
    props: {
        data: {
            default: () => [],
            type: Array,
        },
        options: {
            default: () => DEFAULT_OPTIONS,
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const sliderOptions = computed(() => {
            return {
                ...DEFAULT_OPTIONS,
                ...props.options,
            };
        });
        return {
            css,
            sliderOptions,
        };
    },
});
